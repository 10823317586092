@use 'styles/breakpoints' as *;

.container {
  margin: 0 2rem;

  @include desktop {
    margin: auto;
    max-width: 60rem;

    &.medium {
      max-width: 80rem;
    }

    &.large {
      max-width: 105rem;
    }
  }
}
